import { ApplicationController, useDebounce } from 'stimulus-use';

// Connects to data-controller="query-form"
export default class extends ApplicationController {
  static debounces = [
    {
      name: 'handleSearchChange',
      wait: 500,
    },
  ];

  connect() {
    window.addEventListener('turbo:morph', () => {
      this.enable();
    });
    this.enable();
  }

  enable() {
    useDebounce(this);
    this.positionCarret();
  }

  handleCountChange(event) {
    event.preventDefault();
    event.target.form.requestSubmit();
  }

  handleSearchChange(event) {
    event.preventDefault();
    event.target.form.requestSubmit();
  }

  positionCarret() {
    const input = this.element.querySelector('input[type="search"]');
    if (input) {
      const end = input.value.length;
      input.focus();
      input.setSelectionRange(end, end);
    }
  }
}
