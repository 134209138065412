import { ApplicationController } from 'stimulus-use';
import TomSelect from 'tom-select';
import i18n from '../../i18n';

export default class extends ApplicationController {
  static get targets() {
    return ['foodSelect'];
  }

  connect() {
    window.addEventListener('turbo:before-morph-element', (event) => {
      const { target } = event;

      if (target === this.element && this.foodSelectTarget.tomselect) {
        this.foodSelectTarget.tomselect.destroy();
      }
    });
    window.addEventListener('turbo:morph-element', (event) => {
      const { target } = event;

      if (target === this.element) {
        this.enableTomSelect();
      }
    });
    this.enableTomSelect();
  }

  optionItem(item, escape) {
    return `<div
      class="w-full"
      data-unit_id="${item.unit_id || item.unitId}"
      data-portion_quantity="${item.portion_quantity || item.portionQuantity}"
    >
      <div class="flex justify-between">
        <span>${escape(item.name)}</span>
        <span class='ml-1 text-xs text-gray-400 leading-5'>${escape(item.food_list_cname || item.foodListCname)}
        </span>
      </div>
    </div>`;
  }

  enableTomSelect() {
    if (!this.hasFoodSelectTarget) {
      return;
    }
    if (this.foodSelectTarget.tomselect) {
      this.foodSelectTarget.tomselect.destroy();
    }
    const { url, preload } = this.foodSelectTarget.dataset;
    const that = this;
    if (!this.foodSelectTarget.classList.contains('tomselected')) {
      new TomSelect(this.foodSelectTarget, { /* eslint-disable-line no-new */
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        preload,
        maxOptions: 10000,
        plugins: [
          'dropdown_input',
          'virtual_scroll',
        ],
        onFocus() {
          const controlledElement = this.control_input.closest('div.annotation-item');
          controlledElement.dispatchEvent(new Event('click'));
          this.input.dispatchEvent(new Event('focus'));
        },
        firstUrl(query) {
          const formattedUrl = new URL(url);
          formattedUrl.searchParams.append('query', encodeURIComponent(query));
          formattedUrl.searchParams.append('page', 1);
          return formattedUrl.href;
        },
        load(query, callback) {
          const queryUrl = this.getUrl(query);
          const formattedUrl = new URL(queryUrl);

          fetch(queryUrl)
            .then((response) => response.json())
            .then((json) => {
              const { scrollToOption } = this;
              this.scrollToOption = () => {};
              if (json.meta.next > json.meta.page) {
                formattedUrl.searchParams.append('query', encodeURIComponent(query));
                formattedUrl.searchParams.set('page', json.meta.next);
                this.setNextUrl(query, formattedUrl.href);
              }
              const items = json.data.map((item) => ({ id: item.id, ...item.attributes }));
              callback(items);
              this.scrollToOption = scrollToOption;
            }).catch(() => {
              callback();
            });
        },
        render: {
          option(item, escape) {
            return that.optionItem(item, escape);
          },
          item(item, escape) {
            return that.optionItem(item, escape);
          },
          loading_more() {
            return `<div>${i18n.tomSelect.loading}</div>`;
          },
          no_results() {
            return `<div class="no-results">${i18n.tomSelect.noResults}</div>`;
          },
          no_more_results() {
            return `<div>${i18n.tomSelect.noMoreResults}</div>`;
          },
        },
      });
    }
  }
}
